<template>
  <main style="padding-top: 100px">
    <div class="page_header border-bottom element_to_stick">
      <b-container>
        <b-row>
          <b-col md="12">
            <h1>Detii un restaurant?</h1>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container class="margin_30_20">
      <b-row>
        <div class="main-wrapper">
          <div class="page messages">
            <div data-placeholder="messages"></div>
            <div data-bind="scope: 'messages'">
              <!-- ko if: cookieMessages && cookieMessages.length > 0 --><!-- /ko --><!-- ko if: messages().messages && messages().messages.length > 0 --><!-- /ko -->
            </div>
          </div>
          <div class="columns">
            <div class="column main">
              <input name="form_key" type="hidden" value="igZl5wCcEPUSOKTy" />
              <div
                id="authenticationPopup"
                data-bind="scope:'authenticationPopup'"
                style="display: none"
              ></div>

              <p>
       Alege inteligent! Produsele tale pot ajunge acum rapid şi sigur la un număr mult mai mare de clienţi dacă eşti partener Coressy. Ce promitem? Că eforturile noastre pentru a ajunge şi a ne menţine cei mai buni de pe piaţă sunt continue, iar clienţii noştri vor deveni şi clienţii tăi dacă alegi să fii partener Coressy. Scopul nostru este ca ambele părţi implicate să aibă de câştigat.<br/><br/>

                Devino partener Coressy: interesul nostru colectiv este să
                câştigăm. Este un parteneriat cu avantaje pentru noi dar şi
                pentru tine!<br /><br />

                Completează formularul de mai jos, rapid şi simplu, iar noi te
                vom contacta ulterior. Credem în colaborări de succes!
              </p>
              <b-row>
                <b-col offset-md="3" md="6">
                  <div class="form-group">
                    <label>Adresa de E-Mail <small>*Obligatoriu</small></label>
                    <b-form-input
                      placeholder="E-Mail"
                      type="text"
                      v-model="registerData.email"
                    ></b-form-input>
                  </div>
                  <div class="form-group">
                    <label>Nume <small>*Obligatoriu</small></label>
                    <b-form-input
                      placeholder="Nume"
                      type="text"
                      v-model="registerData.first_name"
                    ></b-form-input>
                  </div>
                  <div class="form-group">
                    <label>Prenume <small>*Obligatoriu</small></label>
                    <b-form-input
                      placeholder="Prenume"
                      type="text"
                      v-model="registerData.last_name"
                    ></b-form-input>
                  </div>
                  <div class="form-group">
                    <label>Telefon <small>*Obligatoriu</small></label>
                    <b-form-input
                      placeholder="Telefon"
                      type="text"
                      v-model="registerData.phone"
                    ></b-form-input>
                  </div>
                  <div class="form-group">
                    <label>Localitate <small>*Obligatoriu</small></label>
                    <b-form-input
                      placeholder="Localitate"
                      type="text"
                      v-model="registerData.city"
                    ></b-form-input>
                  </div>
                  <div class="form-group">
                    <label>Mesaj <small>*Obligatoriu</small></label>
                    <b-form-textarea
                      placeholder="Mesaj"
                      type="textarea"
                      rows="3"
                      max-rows="6"
                      v-model="registerData.mesaj"
                    ></b-form-textarea>
                  </div>
                  <b-button @click="registerRestaurant" variant="primary"
                    >Trimite</b-button
                  >
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-row>
      <b-row>
        <b-col md="12">
          <p class="mt-5 text-center">
            <b
              ><span style="text-decoration: underline"
                >ACORD PRELUCRARE ŞI STOCARE DATE CU CARACTER PERSONAL</span
              ></b
            >
          </p>
          <p>
            Prin trimiterea formularului pe siteul www.coressy.com, esti de
            acord sa ne furnizezi informaţii personale, astfel iţi exprimi
            acordul fără echivoc, că eşti informat cu privire la stocarea şi
            prelucrarea datelor personale citind paginile
            <a href="/protectia-datelor">Politica de confidențialitate </a> si
            <a href="/termeni-si-conditii">Termeni si conditii</a> . Acordul
            dumneavoastră este liber consimţit şi fără vreo condiţionare din
            partea noastră.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import Auth from '@/components/auth'

export default {
  name: "InregistrareRestaurant",
  data() {
    return {
      isBusy: false,
      registerData: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        city: "",
        mesaj: "",
        type_entry: 'Partener',
      },
      cities: [],
    };
  },
  methods: {
    async registerRestaurant() {
      let registerResponse = await Auth.formContact(this.registerData)
        if (registerResponse.success) {
          this.registerData = {
            email: "",
            first_name: "",
            last_name: "",
            phone: "",
            city: "",
            mesaj: "",
          };
          this.$toastr.s('Multumim pentru increderea acordata!');
        } else {
          this.$toastr.e('Va rugsam sa completati toate campurile obligatorii');
        }
    },
  },
  mounted() {},
  computed: {},
};
</script>
